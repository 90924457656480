<template>
    <div v-if="success" class="alert alert-success" role="alert">
        <span>{{ message }}</span>
    </div>

    <div id="company" class="form-group" v-if="can_toggle_company">
        <label for="company">Company</label>
        <select class="form-select" id="company_slug" name="company_slug" v-model="fields.company_value" @change="handleCompanyChange">
            <option value="" disabled>Select a Company</option>
            <option v-if="type !== 'rte'" value="" :selected="!fields.company_value">No Company (System Scenario)</option>
            <option v-for="company in companies"
                    :key="company.id || company.slug"
                    :value="company.id || company.slug"
                    :selected="isCompanySelected(company)">
                {{ company.name }}
            </option>
        </select>
        <div v-if="errors && errors.company_slug" class="text-danger">{{ errors.company_slug[0] }}</div>
    </div>

    <div id="subscribercode" class="form-group">
        <label for="subscribercode" required>Subscriber Code</label>
        <select class="form-select" id="subscribercode_slug" name="subscribercode_slug" v-model="fields.subscriber_code_value" required>
            <option value="" disabled>Select a Subscriber Code</option>
            <option v-if="type !== 'rte'" value="default" :selected="!fields.subscriber_code_value">Default</option>
            <option v-for="subscribercode in subscribercodes"
                    :key="subscribercode.id || subscribercode.slug"
                    :value="subscribercode.id || subscribercode.slug"
                    :selected="isSubscriberCodeSelected(subscribercode)">
                {{ subscribercode.name }}
            </option>
        </select>
        <div v-if="errors && errors.subscriber_code" class="text-danger">{{ errors.subscriber_code[0] }}</div>
    </div>

    <div id="rteEndpoint" class="form-group" v-if="type === 'rte'">
        <label for="rteEndpoint" required>Endpoint</label>
        <select class="form-select" id="rte_endpoint_id" name="rte_endpoint_id" v-model="fields.endpoint_value" required>
            <option value="" disabled>Select an Endpoint</option>
            <option v-for="endpoint in endpoints"
                    :key="endpoint.endpoint_id || endpoint.slug"
                    :value="endpoint.endpoint_id || endpoint.slug"
                    :selected="isEndpointSelected(endpoint)">
                {{ endpoint.name }}
            </option>
        </select>
        <div v-if="errors && errors.endpoint" class="text-danger">{{ errors.endpoint[0] }}</div>
    </div>
</template>

<script>
import i18next from "i18next";

export default {
    data() {
        return {
            loaded: true,
            success: false,
            subscribercodes: {},
            endpoints: {},
            errors: {},
            fields: {
                company_value: '',
                subscriber_code_value: '',
                endpoint_value: ''
            },
            message: '',
            urlParams: null
        }
    },
    props: {
        companies: {
            type: Array,
            required: true
        },
        can_toggle_company: {
            type: Boolean,
            required: true
        },
        scenario: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: ''
        }
    },
    mounted() {
        this.fields = {
            company_value: this.getCompanyValue(),
            subscriber_code_value: this.getSubscriberCodeValue(),
            endpoint_value: this.getEndpointValue()
        };

        if(this.fields.company_value) {
            this.handleCompanyChange();
        }
    },
    created() {
        if (!this.can_toggle_company && this.companies.length > 0) {
            const company = this.companies[0];
            this.fields.company_value = company.id || company.slug;
            this.handleCompanyChange();
        }
    },
    methods: {
        getCompanyValue() {
            return this.scenario.company_id ||
                this.scenario.company_slug ||
                '';
        },
        getSubscriberCodeValue() {
            return this.scenario.subscriber_code_id ||
                this.scenario.subscriber_code ||
                '';
        },
        getEndpointValue() {
            return this.scenario.endpoint_id ||
                this.scenario.endpoint_slug ||
                '';
        },
        isCompanySelected(company) {
            return (company.id && company.id === this.scenario.company_id) ||
                (company.slug && company.slug === this.scenario.company_slug);
        },
        isSubscriberCodeSelected(subscribercode) {
            return (subscribercode.id && subscribercode.id === this.scenario.subscriber_code_id) ||
                (subscribercode.slug && subscribercode.slug === this.scenario.subscriber_code);
        },
        isEndpointSelected(endpoint) {
            return (endpoint.id && endpoint.id === this.scenario.endpoint_id) ||
                (endpoint.slug && endpoint.slug === this.scenario.endpoint_slug);
        },
        handleCompanyChange() {
            // Load both subscriber codes and endpoints (if type is rte) when company changes
            this.loadSubscribercodes();
            if (this.type === 'rte') {
                this.loadEndpoints();
            }
        },
        loadSubscribercodes() {
            if (this.fields.company_value) {
                const url = `/get-subscribercodes-by-company/${this.fields.company_value}`;
                const params = this.type ? {params: {type: this.type}} : {};

                axios.get(url, params)
                    .then(response => {
                        this.subscribercodes = response.data;
                    })
                    .catch(error => {
                        console.error('Error loading subscriber codes:', error);
                    });
            } else {
                this.subscribercodes = {};
            }
        },
        loadEndpoints() {
            if (this.fields.company_value) {
                const url = `/get-rteendpoints-by-company/${this.fields.company_value}`;

                axios.get(url)
                    .then(response => {
                        this.endpoints = response.data;
                    })
                    .catch(error => {
                        console.error('Error loading RTE endpoints:', error);
                    });
            } else {
                this.endpoints = {};
            }
        }
    }
}
</script>
